<template>
    <div class="workers" v-if="!userIsPoint">
        <v-row style="max-width: 1400px" class="mx-auto">
            <v-col cols="12">
                <v-card align="center" justify="center" class="mx-auto elevation-6">
                    <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            {{ $t('workers.list_of_workers', { p1: workers.length, p2: workersOnlineCount, p3: workersFreeCount }) }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span class="tooltip-text" v-bind="attrs" v-on="on">
                                    <v-btn @click="onClickCalendar" class="mx-4 px-2" tile icon rounded>
                                        <v-img src="../../assets/images/excel-300.png" width="40"></v-img>
                                    </v-btn>
                                </span>
                            </template>
                            <span>Выгрузка заработка сервисменов</span>
                        </v-tooltip>

                        <v-btn icon @click="onClickUpload">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-title class="pt-0 pb-2 d-flex">

                        <v-select class="mx-2 flex-grow-0" style="width: 220px;" no-data-text="Нет данных" @change="updateData" :items="filter_statuses" name="order_mode" v-model="filter_status" label="По статусу" hide-details>
                            <template slot="selection" slot-scope="data">
                                <v-icon v-if="data.item.icon" small color="#444" class="mr-2">{{ data.item.icon }}</v-icon
                                >{{ data.item.text }}
                            </template>
                            <template slot="item" slot-scope="data">
                                <v-icon v-if="data.item.icon" small color="#444" class="mr-2">{{ data.item.icon }}</v-icon
                                >{{ data.item.text }}
                            </template>
                        </v-select>

                        <v-select class="mx-2 flex-grow-0" style="width: 220px;" no-data-text="Нет данных" @change="updateData" :items="filter_enables" name="order_mode" v-model="filter_enable" label="По подключению" hide-details></v-select>
                        
                        <v-select v-if="userIsOperator" class="mx-2 flex-grow-0" style="width: 220px;" no-data-text="Нет данных" @change="updateData" :items="filter_services" name="order_mode" item-text="name" item-value="id" v-model="filter_service" label="По сервису" hide-details></v-select>

                        <v-spacer></v-spacer>
                        
                        <v-text-field style="width: 340px; flex: initial" v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details></v-text-field>
                    </v-card-title>

                    <v-data-table
                        :mobile-breakpoint="0"
                        dense
                        no-data-text="Нет данных"
                        no-results-text="Нет данных, попробуйте изменить запрос"
                        :footer-props="{
                            pageText: '{0} - {1} из {2}',
                            itemsPerPageText: 'Показывать на странице',
                            itemsPerPageAllText: 'все',
                            showFirstLastPage: true,
                            itemsPerPageOptions: [25, 50, 100, -1],
                        }"
                        :headers="headers"
                        :search="search"
                        :items="workers"
                        item-key="index"
                        class="elevation-0"
                        :loading="loading"
                        :loading-text="$t('common.loading')"
                        @click:row="onClickWorkerEdit($event)"
                    >
                        <template v-slot:[`item.full_name`]="{ item }">
                            <v-avatar size="24" class="mr-2">
                                <img alt="serviceman" :src="`${config.api.delivery + 'user_avatar?tid=' + item.tid}`" />
                            </v-avatar>
                            <b>{{ item.full_name.length > 30 ? item.full_name.substr(0, 30) + '...' : item.full_name }}</b>
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                            <div class="d-flex">
                                <v-chip :color="courierStatusColorHtml(item.status)" text-color="white">
                                    <v-icon size="small" class="mr-1">{{ workerStatusIcon(item.status) }}</v-icon>
                                    {{ workerStatusStr(item.status) }}
                                </v-chip>
                                <v-btn class="mt-1" v-if="item.status != config.user.status.free || item.enable" outlined text tile x-small @click="onClickStatusEdit(item)"><v-icon small>mdi-pencil-outline</v-icon></v-btn>
                            </div>
                        </template>

                        <template v-slot:[`item.enable`]="{ item }">
                            <span :style="{ color: courierConnectColor(item.enable) }">{{ courierConnectStatus(item.enable) }}</span>
                        </template>

                        <template v-slot:[`item.mynex_ident`]="{ item }">
                            <span :style="{ color: courierConnectColor(item.mynex_ident != null) }">{{ courierConnectStatus(item.mynex_ident != null) }}</span>
                        </template>

                        <template v-slot:[`item.settings.typeSpecs`]="{ item }">
                            <span>{{ getSpecNames(item.settings.typeSpecs) }}</span>
                        </template>

                        <template v-slot:[`item.service_name`]="{ item }">
                             <span>{{ item.service_name?item.service_name:'------------------' }}</span>
                        </template>

                        <template v-slot:[`item.setting`]="{ item }">
                            <span :style="{ color: courierConnectColor(item.mynex_ident != null) }">{{ courierConnectStatus(item.mynex_ident != null) }}</span>
                        </template>

                        <template v-slot:[`item.ordersToday`]="{ item }">
                            <span style="width:35px;display: inline-block;">{{ item.ordersToday }}</span>
                            <v-btn v-if="item.ordersToday" outlined text tile x-small @click="onClickTodayDetails(item)"><v-icon small>mdi-menu</v-icon></v-btn>
                        </template>

                        <template v-slot:[`item.ordersYesterday`]="{ item }">
                            <span style="width:35px;display: inline-block;">{{ item.ordersYesterday }}</span>
                            <v-btn v-if="item.ordersYesterday" outlined text tile x-small @click="onClickYesterdayDetails(item)"><v-icon small>mdi-menu</v-icon></v-btn>
                        </template>

                        <template v-slot:[`item.ordersMonth`]="{ item }">
                            <span style="width:35px;display: inline-block;">{{ item.ordersMonth }}</span>
                            <v-btn v-if="item.ordersMonth" outlined text tile x-small @click="onClickMonthDetails(item)"><v-icon small>mdi-menu</v-icon></v-btn>
                        </template>

                        <template v-slot:[`item.service_id`]="{ item }">
                            <div class="text-no-wrap">
                                <span class="mr-1" style="inline-block;">{{ getServiceName(item.service_id ? item.service_id : 0) }}</span>
                            </div>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <div class="text-center">
            <v-dialog v-model="dialogStatus" width="unset">
                <v-card>
                    <v-card-title class="headline grey lighten-2">
                        {{ dialogHeader }}
                    </v-card-title>

                    <v-card-text>
                        <v-row>

                            <v-col cols="12" v-if="currentItem.status != config.user.status.free">
                                <div>
                                    <p>Заказы, с которыми работает мастер сделать как:</p>
                                    <v-radio-group
                                      v-model="orders_status"
                                      mandatory
                                        hide-details
                                    >
                                    <v-radio
                                        label="Новые"
                                        :color="orderStatusColorHtml(config.order.status.new)"
                                        :value="config.order.status.new"
                                    ></v-radio>
                                    <v-radio
                                        label="Завершенные"
                                        :color="orderStatusColorHtml(config.order.status.complete)"
                                        :value="config.order.status.complete"
                                    ></v-radio>
                                    </v-radio-group>
                                </div>
                            </v-col>
                            <v-col cols="12" class="d-flex px-4 pb-5 justify-md-space-around">
                                <v-btn class="ma-2" v-if="currentItem.status == config.user.status.free&&currentItem.service_id" color="error" dark @click="onClickWorkerDelete()">Отключить</v-btn>
                                <v-btn v-if="currentItem.status != config.user.status.free" :color="courierStatusColorHtml(config.user.status.free)" dark @click="onClickWorkerFree()">Свободен</v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="!currentItem.service_id" class="mb-3 justify-md-space-around">
                            <v-card class="d-flex flex-column ma-2 pa-4">
                                    <p>Сервисмен не подключен к какому-либо сервису,<br> вы можете присоединить его к себе</p>
                                    <v-btn class="ma-2"  color="blue" dark @click="onClickWorkerAdd()">Присоединить</v-btn>
                            </v-card>
                        </v-row>

                        <v-divider></v-divider>
                        <span v-html="dialogContent"></span>
                    </v-card-text>

                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogOrders" width="720" scrollable>
                <v-card :loading="loadingOrder">
                    <template slot="progress">
                        <v-progress-linear indeterminate absolute top color="deep-purple accent-4"></v-progress-linear>
                    </template>

                    <v-card-title class="headline grey lighten-2">
                        {{ dialogHeader }}
                    </v-card-title>

                    <v-card-text class="py-4">
                        <div class="text-center">
                            <v-pagination v-model="page" :length="Math.ceil(orders.length / perPage)"></v-pagination>
                        </div>
                        <span v-if="loadingOrder && !visibleOrders.length">{{ $t('common.loading') }}</span>
                        <div v-for="(order, i) of visibleOrders" :key="order.id" class="d-flex">
                            {{ ((page-1) * perPage + i + 1) }}.
                            <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" class="item-order d-inline-block mx-3" :style="orderStatusStyle(order)">{{ order.id }}</span>
                                </template>
                                <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                    <div class="order-card" v-html="orderContent(order,true)"></div>
                                </v-card>
                            </v-menu>
                            <span v-html="orderListHeader(order)"></span>
                            <br />
                        </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="mx-2" color="primary" dark @click="updateDetails">Обновить</v-btn>
                        <v-btn class="mx-2" color="primary" text @click="dialogOrders = false">Закрыть</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog v-model="dialogEdit" width="440" scrollable>
                <v-card :loading="loadingEdit">
                    <template slot="progress">
                        <v-progress-linear indeterminate absolute top color="deep-purple accent-4"></v-progress-linear>
                    </template>

                    <v-card-title class="headline grey lighten-2">
                        {{ dialogHeader }}
                    </v-card-title>

                    <v-card-text class="py-0 mt-1">
                        <div class="d-flex">
                            <v-avatar size="90" class="mr-1 elevation-4">
                                <img alt="courier" :src="`${config.api.delivery + 'user_avatar?tid=' + selectedItem.tid}`" />
                            </v-avatar>
                            <v-text-field class="my-7 mx-4 flex-grow-0" style="width: 100%" v-model="selectedItem.full_name" label="Имя Фамилия" hide-details></v-text-field>
                        </div>
                    </v-card-text>

                    <v-card-text class="py-0">
                        <v-text-field class="ml-2 mr-4 flex-grow-0" v-model="currentPhone" label="Номер телефона"></v-text-field>
                    </v-card-text>

                    <v-card-text v-if="userIsOperator" class="py-0">
                        <v-select class="ml-2 mr-4 flex-grow-0" no-data-text="Нет данных" :items="services" name="service" item-text="name" item-value="id" v-model="currentService" label="Привязать к сервису"></v-select>
                    </v-card-text>


                    <v-divider></v-divider>

                    <v-card-actions>
                            <v-btn class="mx-2" color="success" dark @click="onClickSave">Сохранить</v-btn>
                            <v-spacer></v-spacer>
                            <v-dialog v-if="currentItem.status == config.user.status.free" v-model="validDeleted" persistent max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    class="mr-5"
                                    color="error"
                                    v-bind="attrs"
                                    v-if="userIsOperator"
                                    v-on="on"
                                    dark
                                    text
                                    >
                                    Удалить
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title class="text-h5">
                                    Точно удалить?
                                    </v-card-title> 
                                    <v-card-text>Восстановить сервисмена <b>{{ currentFullName }}</b> будет невозможно с помощью сайта, необходимо будет обращаться в службу поддержки, все равно удалить?</v-card-text>
                                    <v-card-actions>
                                    <v-btn
                                        class="ml-5"
                                        color="green darken-1"
                                        text
                                        @click="validDeleted = false"
                                    >
                                        Отмена
                                    </v-btn>

                                    <v-spacer></v-spacer>
                                        <v-btn class="mr-5" color="error" @click="onClickDelete">Удалить</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        <v-btn color="primary" text @click="dialogEdit = false">Закрыть</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog ref="dialog" v-model="calendar" :return-value.sync="dates" persistent width="320px">
                <v-card>
                    <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            {{ datesText  }}
                        </v-toolbar-title>
                    </v-toolbar>
                    <template>
                        <v-date-picker class="d-block" no-title range v-model="dates" locale="ru-ru" scrollable :selected-items-text="datesText" :first-day-of-week="1" @input="onClickChangeDate">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-2 px-4" text color="primary" @click="calendar = false">
                                Отмена
                            </v-btn>
                            <v-btn class="mx-2 px-4" color="success" :style="downloadDataDisable ? { color: '#999 !important' } : {}" dark @click="onClickDownloadData" :disabled="downloadDataDisable">
                                Загрузить
                            </v-btn>
                        </v-date-picker>
                    </template>
                </v-card>
            </v-dialog>

        </div>

        <span class="pb-16 d-block" />
    </div>
</template>

<script>
    import config from '../../config'
    import moment from 'moment-timezone'

    moment.locale('ru')

    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'
    import Orders from '../../mixins/Orders.js'

    export default {
        mixins: [DateTime, Status, Orders],
        data() {
            return {
                //подтверждение на удаление курьера
                validDeleted: false,
                loading: false,
                loadingOrder: false,
                loadingEdit: false,
                calendar: false,
                orders_status: 0,
                dates: [],
                config,
                search: '',
                headers: [
                    {
                        text: 'Имя Фамилия',
                        align: 'start',
                        value: 'full_name',
                    },
                    {
                        text: 'Текущий статус',
                        value: 'status',
                    },
                    {
                        text: 'Подключение',
                        value: 'enable',
                    },
                    {
                        text: 'Трекер',
                        value: 'mynex_ident',
                    },
                    {
                        text: 'Специализация',
                        value: 'settings.typeSpecs',
                    },
                    {
                        text: 'Сервис',
                        value: 'service_name',
                    },
                    {
                        text: 'Заказы сегодня',
                        value: 'ordersToday',
                    },
                    {
                        text: 'Заказы вчера',
                        value: 'ordersYesterday',
                    },
                    {
                        text: 'Заказы за 30 дней',
                        value: 'ordersMonth',
                    },
                ],
                workers:[],
                workersOrig:[],
                workersOnlineCount:0,
                workersFreeCount: 0,
                dialogOrders: false,
                dialogEdit: false,
                dialogStatus: false,
                datesText: '',
                dialogHeader: '',
                dialogContent: '',
                currentItem: {},
                selectedItem: {},
                filter_status: -1,
                filter_statuses: [
                    {
                        text: '< Все >',
                        value: -1,
                    },
                    {
                        text: 'Свободен',
                        value: config.user.status.free,
                        icon: 'mdi-check-bold',
                    },
                    {
                        text: 'В работе',
                        value: config.user.status.delivering,
                        icon: 'mdi-hammer-screwdriver',
                    },
                ],
    
                filter_enable: -1,
                filter_enables: [
                    {
                        text: '< Все >',
                        value: -1,
                    },
                    {
                        text: 'Отключен',
                        value: 0,
                    },
                    {
                        text: 'Подключен',
                        value: 1,
                    },
                ],
                detailsWorker: 0,
                detailsDate1: '',
                detailsDate2: '',
                currentService: 0,
                currentPhone: '',
                services: [],
                orders: [],
                specs:[],
                filter_services: [],
                filter_service: -1,
                downloadDataDisable: true,
                changeFlag: false,
                page: 1,
                perPage: config.paginateItemsCount,
                userIsPoint: false,
                userIsOperator:false,
                userIsService:false
            }
        },
        components: {},

        mounted() {
            console.log('start!')
            this.userIsOperator = this.$store.state.user.role == config.user.role.operator
            this.userIsPoint = this.$store.state.user.role !== config.user.role.operator
            this.userIsService = this.$store.state.user.role == config.user.role.service
            if (!this.userIsPoint) {
                this.uploadData()
            }
            console.log(moment().add().startOf('day').utc().format())
        },

        beforeDestroy() {},

        destroyed() {},
        watch: {},
        computed: {
            visibleOrders() {
                return this.orders.slice((this.page - 1) * this.perPage, this.page * this.perPage)
            },
            currentFullName(){
                if (this.currentItem.full_name !== undefined) {
                    const name = this.currentItem.full_name
                    return name.length > 30 ? name.substr(0,30) + '...' :name
                }
                return '-'
            }
        },
        methods: {
            updatePaginate() {},
            onClickChangeDate() {
                this.downloadDataDisable = true
                if (this.dates.length == 2) {
                    if (moment(this.dates[0]).isAfter(moment(this.dates[1]))) {
                        const date = this.dates[0]
                        this.dates[0] = this.dates[1]
                        this.dates[1] = date
                    }
                    this.datesText = moment(this.dates[0]).format('D MMM') + ' - ' + moment(this.dates[1]).format('D MMM')
                    this.downloadDataDisable = false
                } else {
                    this.datesText = moment(this.dates[0]).format('D MMM')
                }
            },

            onClickWorkerFree() { //ИЗМЕНЕН
                let worker = this.workersOrig.find(w => w.tid == this.currentItem.tid)
                console.log('free', worker)

                if (worker) {
                    worker.act_ord = 0
                    worker.status = config.user.status.free
                    this.$store
                        .dispatch('user_set', worker)
                        .then(res => {
                            if (res.data.success) {
                                this.workersFreeCount++
                                this.updateData()
                                console.log('user saved ok')
                            } else {
                                console.log('order saved fail')
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }

                this.$store
                    .dispatch('worker_orders_status', { worker_tid: worker.tid, status: this.orders_status })
                    .then(res => {
                        if (res.data.success) {
                            console.log('order saved ok')
                        } else {
                            console.log('order saved fail')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                this.dialogStatus = false
            },
            onClickWorkerDelete() { //ИЗМЕНЕН
                let worker = this.workersOrig.find(w => w.tid == this.currentItem.tid)
                if (worker) {
                    worker.enable = 0
                    this.$store
                        .dispatch('user_set', worker)
                        .then(res => {
                            if (res.data.success) {
                                this.updateData()
                                console.log('user saved ok')
                            } else {
                                console.log('order saved fail')
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
                this.dialogStatus = false
            },
            onClickWorkerAdd(){
                let worker = this.workersOrig.find(w => w.tid == this.currentItem.tid)
                if (worker) {
                    worker.service_id = this.$store.state.user.service_id
                    this.$store
                        .dispatch('user_set', worker)
                        .then(res => {
                            if (res.data.success) {
                                this.updateData()
                                console.log('user saved ok')
                            } else {
                                console.log('order saved fail')
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
                this.dialogStatus = false
                this.uploadData()
            },
             onClickDownloadData() {
                this.calendar = false
                console.log('dates', this.dates)
                const d1 = this.getDate1StringFromDate(this.dates[0])
                const d2 = this.getDate2StringFromDate(this.dates[1])
                let condition=''
                console.log(this.userIsService)
                if(this.userIsService){
                    condition = `&service_id=${this.$store.state.user.service_id}`
                }else{
                    condition = `&operator_tid=${this.$store.state.user.tid}`
                }
                let url = `${config.api.delivery}workers_salary?tz=${this.$store.state.settings.tz}&tz_string=${this.$store.state.settings.tz_string}${condition}&date1=${d1}&date2=${d2}&deliveryTime=true`
                window.location.href = url
            },
             onClickCalendar() {
                this.datesText = 'Выберите даты "с" и "по"'
                this.calendar = true
                this.downloadDataDisable = true
            },
            onClickWorkerEdit(item) { // ИЗМЕНЕН
                if (this.dialogStatus) return
                if (this.dialogOrders) return
                if (!item.service_id) return
                this.dialogHeader = `Сервисмен ${item.full_name}`
                this.currentItem = item
                this.selectedItem = JSON.parse(JSON.stringify(this.currentItem))
                this.currentService = this.selectedItem.service_id
                this.currentPhone = this.selectedItem.settings.phone
                this.dialogEdit = true
            },
            onClickStatusEdit(item) { //ИЗМЕНЕН
                this.orders_status = config.order.status.complete
                this.dialogHeader = `Изменить статус для ${item.full_name}`
                this.dialogContent = `Текущий статус сервисмена: <span style="color: ${this.courierStatusColorHtml(item.status)};">${this.workerStatusStr(item.status)}</span>`
                this.currentItem = item
                this.dialogStatus = true
            },
            onClickTodayDetails(worker) {
                this.page = 1
                this.orders = []
                this.dialogHeader = `${worker.full_name} - заказы сегодня (${worker.ordersToday})`
                this.detailsWorker = worker.tid
                this.detailsDate1 = this.getDate1String()
                this.detailsDate2 = this.getDate2String()
                this.dialogContent = this.$t('common.loading')
                this.updateDetails()
                this.dialogOrders = true
            },
            onClickYesterdayDetails(worker) {
                this.page = 1
                this.orders = []
                this.dialogHeader = `${worker.full_name} - заказы вчера (${worker.ordersYesterday})`
                this.detailsWorker = worker.tid
                this.detailsDate1 = this.getDate1String(-1)
                this.detailsDate2 = this.getDate2String(-1)
                this.dialogContent = this.$t('common.loading')
                this.updateDetails()
                this.dialogOrders = true
            },
            onClickMonthDetails(worker) {
                this.page = 1
                this.orders = []
                this.dialogHeader = `${worker.full_name} - заказы за 30 дней (${worker.ordersMonth})`
                this.detailsWorker = worker.tid
                this.detailsDate1 = this.getDate1String(-30)
                this.detailsDate2 = this.getDate2String()
                this.dialogContent = this.$t('common.loading')
                this.updateDetails()
                this.dialogOrders = true
            },
            onClickSave() {  //ИЗМЕНЕН
                if (this.currentItem.full_name != this.selectedItem.full_name) {
                    this.currentItem.full_name = this.selectedItem.full_name
                    this.changeFlag = true
                }
                if (this.currentItem.service_id != this.currentService) {
                    this.currentItem.service_id = this.currentService
                    this.changeFlag = true
                }
                if (this.currentItem.settings.phone != this.currentPhone) {
                    this.currentItem.settings.phone = this.currentPhone
                    this.changeFlag = true
                }

                this.dialogEdit = false

                if (this.changeFlag) {
                    this.$store
                        .dispatch('user_set', this.currentItem)
                        .then(res => {
                            if (res.data.success) {
                                console.log('user saved ok')
                            } else {
                                console.log('user saved fail')
                            }
                            this.changeFlag = false
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
            },

            onClickDelete(){ //ИЗМЕНЕН
                this.dialogEdit = false
                const workerId = this.currentItem.id
                this.$store
                    .dispatch('user_delete', workerId)
                    .then((res) => {
                        if(res.data.success) {
                            console.log('delete ok');
                            this.workers = this.workers.filter(w => w.id != workerId)
                            this.notifyDeleteCourier()
                        } else {
                            console.log('delete false');
                        }
                    })
                console.log('test currentItem = ', this.currentItem);

            },

            notifyDeleteWorker(){ //ИЗМЕНЕН
                const data = {
                    workerName: this.currentItem.full_name,
                    workerTid: this.currentItem.tid,
                    serviceTid: this.currentItem.operator_tid
                }
                this.$store
                .dispatch('worker_delete_notify', data)
                .then((res) => {
                    if(res.data.success){
                        console.log('notify ok');
                    }
                })
                .catch((err) => {
                    console.log('notify false', err.name, err.message);
                })
            },

            updateDetails() {
                this.loadingOrder = true

                this.$store
                    .dispatch('orders_date', {
                        worker: this.detailsWorker,
                        date1: this.detailsDate1,
                        date2: this.detailsDate2,
                        service:true,
                        deliveryTime: true,
                    })
                    .then(res => {
                        this.dialogContent = ''
                        if (res.data.success) {
                            this.orders = res.data.orders.filter(o => o.status != config.order.status.forming)
                        } else {
                            this.dialogContent = this.$t('common.loading_error', { p1: 1 })
                        }
                        this.loadingOrder = false
                    })
                    .catch(err => {
                        this.loadingOrder = false
                        console.log('point_orders_get error', err)
                        this.dialogContent = this.$t('common.loading_error', { p1: 2 })
                    })
            },
            onClickUpload() {
                this.uploadData()
            },
            getServiceName(service_id) { //ИЗМЕНЕН
                let serviceName = 'Неизвестно'
                const service = this.services.find(s => s.id == service_id)
                if (service) {
                    serviceName = service.name
                }
                return serviceName
            },
            getServiceTypes() { //НОВЫЙ
                this.$store
                .dispatch('service_specs')
                .then(res=>{
                    this.specs=res.data.serviceSpecs
                }).catch(err=>{
                    this.specs=[]
                })
            },
            getSpecNames(specs){
                let names = ''
                specs=Array.isArray(specs)?specs:specs?[specs]:false
                if(specs!==false){
                    for (const spec of this.specs) {
                        if (specs.includes(spec.id)) names += names == '' ? spec.name : ', ' + spec.name
                    }   
                }else{
                    return 'Неизвестно'
                }
                return names
            },
            uploadData() {   //ИЗМЕНЕН
                this.loading = true
                let params ={
                    service_id:this.userIsService?this.$store.state.user.service_id:0
                }
                this.$store
                    .dispatch('workers_with_all_orders',params)
                    .then(res => {
                        if (res.data.success) {
                            this.workersOnlineCount = 0
                            this.workersOrig = res.data.workers.map((worker, i) => {
                                if (worker.enable) this.workersOnlineCount++
                                return worker
                            })
                            //this.workers = JSON.parse(JSON.stringify(this.couriersOrig))
                            this.workersFreeCount = this.workers.filter(w => w.enable && w.status == config.user.status.free).length
                            console.log('workers', this.workers)
                            this.updateData()
                        }
                        if(this.userIsService){
                            this.filter_service = this.$store.state.user.service_id
                            this.updateData()
                            this.loading = false
                        }else{
                            this.$store
                            .dispatch('services_get_all')
                            .then(res => {
                                let services = []
                                if (res.data.success) {
                                    services = res.data.services
                                    console.log('services', services)
                                    this.services = services.map(s => ({ id: s.id, name: s.name }))
                           
                                    this.filter_services = JSON.parse(JSON.stringify(this.services))
                                    this.filter_services.splice(0, 0, { id: -1, name: '< Все >' })
                                }
                                
                                this.loading = false
                            })
                            .catch(err => {
                                this.loading = false
                                console.log(err)
                            })
                        }
                    })
                    .catch(err => {
                        this.loading = false
                        console.log(err)
                    })
                this.getServiceTypes()
            },
            updateData() { //ИЗМЕНЕН
                this.workers = JSON.parse(JSON.stringify(this.workersOrig))
                if (this.filter_status != -1) {
                    console.log('this.filter_status', this.filter_status)
                    this.workers = this.workers.filter(w => w.status == this.filter_status)
                }
                if (this.filter_enable != -1) {
                    this.workers = this.workers.filter(w => w.enable == this.filter_enable)
                }
                if (this.filter_service != -1) {
                    this.workers = this.workers.filter(w => w.service_id == this.filter_service)
                }
                this.workersOnlineCount = 0
                this.workersFreeCount=0
                for(let w of this.workers){
                    if (w.enable) this.workersOnlineCount++
                    if (w.act_ord==0) this.workersFreeCount++
                }
               
                console.log('this.workers', this.workers)
            },
            orderListHeader(order) {
                return ` <b>${ order.name }</b> - ${ moment(order.date).format('D MMM HH:mm') } - <span style="color: ${ this.orderStatusColorHtml(order.status) };">${ this.orderStatusStr(order.status,true) }${ order.date_complete ? ` (${ moment(order.date_complete).format('D MMM HH:mm') })` : '' }</span><br>`
            },
        },
    }
</script>

<style lang="stylus">

    .workers
        height 100%
        padding-top 10px !important

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    padding 0 8px
                    white-space nowrap
            .v-data-table__wrapper > table > tbody > tr
                cursor pointer
                // background none !important
                td
                    white-space nowrap
                    padding 0 8px
                    font-size 0.75rem
                    p
                        margin-top 6px
                        margin-bottom 6px

        .expanded-content
            box-shadow inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75), inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75)
            background none !important
            &:hover
                // cursor default !important

        .th-first-item
            width 30%

        .v-chip
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px
                cursor pointer
                padding 0 6px

        b
            font-weight 500

    .couriers-dialog
        .v-list
            overflow-y auto
            height 132px
            .list-group
                height 100%
                .v-list-item
                    cursor pointer
                    &:hover
                        background-color beige
                .sortable-ghost
                    background-color beige
</style>
